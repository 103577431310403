
@import "Colors.css"; 

.app {
	min-height: 100vh;
	-webkit-touch-callout: none;
	margin: 0;
	width: auto;
	background: var(--backgroundColor);
	color: var(--altFontColor);
}

.menuText {
	margin-top: 80px;
	margin-left: 50px;
}

.spinner-grow {
	height: 100px !important;
	width: 100px !important;
	padding: 30px !important;
	margin-left: calc(50vw - 50px);
	margin-top: calc(30vh - 50px);
	float: center;
	color: var(--mainColor);
}

.buttonsDiv {
	width: 100%;
	max-width: 500px;
	align-content: center;
	padding-bottom: 30px;
	margin-top: -35px;
	
}

.containerList {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: top;
	align-items: stretch;
	align-content: stretch;
}

.containerOffer {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: top;
	align-items: stretch;
	align-content: stretch;
}

.listGroupTitle {
	display: flex;
	background-color: var(--altColor);
	color: var(--titleFontColor);
	border: 3px solid var(--mainBorderColor); 
	border-width: 0px 0px 3px 0px;
	height: 40px;
	justify-content: center;
	align-items: center;
	margin-bottom: 0px;
}

.offerTitle {
	display: flex;
	background-color: var(--altColor);
	color: var(--titleFontColor);
	border: 3px solid var(--mainBorderColor); 
	border-width: 0px 0px 3px 0px;
	height: 5vh;
	min-height: 35px;
	justify-content: center;
	align-items: center;
	margin-bottom: 0px;
}

.swipeTitle {
	display: flex;
	background-color: var(--altColor);
	color: var(--titleFontColor);
	border: 3px solid var(--mainBorderColor); 
	border-width: 0px 0px 3px 0px;
	height: 5vh;
	min-height: 35px;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin-bottom: 0px; 
}

.listGroupItems {
	overflow-y: auto;
	color: var(--mainFontColor);
	background-color: var(--altBackgroundColor) !important;
	border-radius: 0;
}

.headerDiv {
	height: 80px;
	min-height: 70px;
}

.itemsDiv {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: top;
	align-items: stretch;
	align-content: stretch;
	flex-grow: 1;
	max-width: 100vw;
	/* height: calc(100vh - 80px); */
}

.modalCloseButton {
	background-color: var(--buttonColor);
	margin-top: 30px;
	border-radius: 15px;
	vertical-align: center;
	text-align: center;
	width: calc(100% - 60px);
	height: 7vh;
	min-height: 40px;
	border: 3px solid var(--mainBorderColor);
	padding: 3px;
	z-index: 30;
	color: var(--buttonFontColor);
	margin-left: 10%;
	margin-right: 10%;
	transition: all 0.4s;
	cursor: pointer;
	max-width: 400px;
}


.offerDiv {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: top;
	align-items: stretch;
	align-content: stretch;
	flex-grow: 1;
	max-width: 100vw;
	min-height: 85vh;
	overflow-y: auto;
}

.offerDetail {
	background-color: var(--altBackgroundColor);
	height: 100%;
	min-height: 80vh;
	color: var(--mainFontColor);
}

.offerDetailsRow {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: left;
	align-content: stretch;
	margin-left: 50px;
}

.offerImagesRow {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-content: stretch;
	width: 90vw;
	overflow-x: auto;
	margin-left: 5vw;
	margin-bottom: 8vh;
	margin-top: 3vh;
}

.menuImage {
	float: right;
	margin-right: 30px;
	margin-top: 15px;
	height: 50px;
}

.offerOptionsRow {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-content: stretch;
	width: 90vw;
	margin-left: 5vw;
	margin-bottom: 8vh;
	margin-top: 5vh;
}

.homeImageDiv {
	margin-top: 30px;
}


button:active {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24), 0 6px 25px 0 rgba(0, 0, 0, 0.19);
	transform: translateY(2px);
}

button:hover{
	box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.24), 0 8px 25px 0 rgba(0, 0, 0, 0.19);
}


.listGroupItem:hover {
	box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.24), 0 8px 20px 0 rgba(0, 0, 0, 0.15);
}

.listGroupItem:active {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24), 0 6px 25px 0 rgba(0, 0, 0, 0.19);
	transform: translateY(2px);
}

.homeButton {
	background-color: var(--buttonColor);
	margin-top: 30px;
	border-radius: 15px;
	vertical-align: center;
	text-align: center;
	width: 88%;
	height: 7vh;
	min-height: 40px;
	border: 3px solid var(--mainBorderColor);
	padding: 3px;
	z-index: 30;
	color: var(--buttonFontColor);
	margin-left: 6%;
	margin-right: 6%;
	transition: all 0.4s;
	cursor: pointer;
	max-width: 400px;
}


.backButton {
	background-color: var(--buttonColor);
	border-radius: 15px;
	vertical-align: center;
	text-align: center;
	width: 50px;
	height: 35px;
	/* border: 3px solid var(--mainBorderColor); */
	padding: 3px;
	z-index: 30;
	color: var(--buttonFontColor);
	transition: all 0.4s;
	cursor: pointer;
	margin-top: 5px;
	margin-left: 5px;
}


.homeButton span {
	cursor: pointer;
	display: inline-block;
	position: relative;
	transition: 0.4s;
}
.homeButton span:after {
	content: '\00bb';
	position: absolute;
	opacity: 0;
	top: 0;
	right: -20px;
	transition: 0.5s;
}
.homeButton:hover span {
	padding-right: 25px;
}
.homeButton:hover span:after {
	opacity: 1;
	right: 0;
}

.container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: top;
	align-items: center;
	align-content: stretch;
	flex-grow: 1;
	height: 100%;
}

.dayNav {
	/* align-self: center; */
	width: 100vw;
	height: 60px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
}

.nav {
	height: 60px;
	width: 100vw;
}

.react-date-picker, .react-date-picker *, .react-date-picker *:before, .react-date-picker *:after {
	border-radius: 5px;
}


.react-date-picker__button__icon {
	stroke: var(--buttonFontColor) !important;	
}

.react-date-picker, .react-date-picker *, .react-date-picker *:before, .react-date-picker *:after {
	border-radius: 5px;
	background: var(--buttonColor);
	color: var(--buttonFontColor);
	border-width: 0 !important;
}

.react-date-picker {
	border: 3px solid var(--mainBorderColor) !important;
}

.react-calendar {
	background: var(--buttonColor) !important;
	color: var(--buttonFontColor) !important;	
}

.react-calendar abbr {
	background: transparent !important;
}



.react-date-picker__calendar {
	border: 3px solid var(--mainBorderColor) !important;
	margin-left: -3px;
}

.react-calendar__tile:hover abbr{
	color: var(--mainBorderColor) !important;	 
}


.datePicker {
	align-self: center;
	height: 35px;
	background: var(--buttonColor);
	color: var(--buttonFontColor);
}

.navButtons {
	align-self: center;
	height: 35px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: top;
	align-items: center;
	align-content: center;
	flex-grow: 1;
}

.navButton {
	background: var(--buttonColor);
	color: var(--buttonFontColor);
	border-radius: 5px;
	height: 35px;
	padding-bottom: 3px;
	margin-left: 5px;
	border: 3px solid var(--mainBorderColor);
}
