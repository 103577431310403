@import "Colors.css"; 

.containerLogin {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  padding-bottom: 30px;
  min-height: 100vh;
}

.rowLogin {
  margin-left: 10vw;
  width: 80vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}


.loginText {
  margin-left: 30px;
  margin-right: 30px;
  font-size: 1.1em;
}

.loginInput {
  font-size: 1.1em;
  height: 15px;
	border: 3px solid var(--mainBorderColor);
  border-radius: 10px;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y));
}

.form-label-group input:not(:placeholder-shown)~label {
  padding-top: calc(var(--input-padding-y) / 2);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #000000;
}

:root {
  --input-padding-x: 1.2rem;
  --input-padding-y: 0.9rem;
}

.form-label-group>label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  line-height: 0;
  color: black;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
  margin-top: 5px;
}

.form-label-group>input,
.form-label-group>label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group {
  position: relative;
  margin-bottom: 10px;
}

.loginButton {
	background-color: var(--buttonColor);
	margin-top: 20px;
	border-radius: 15px;
	vertical-align: center;
	text-align: center;
	width: 100%;
  height: 7vh;
  min-height: 40px;
	border: 3px solid var(--mainBorderColor);
	padding: 3px;
	z-index: 30;
  color: var(--buttonFontColor);
  transition: all 0.4s;
  cursor: pointer;
}

.loginButtonText {
  display: inline;
  margin-left: 10px;
}


.loginButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.4s;
}
.loginButton span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
.loginButton:hover span {
  padding-right: 25px;
}
.loginButton:hover span:after {
  opacity: 1;
  right: 0;
}