* {
    --mainFontColor: #ffffff;
    --altFontColor: #264153;
    --buttonFontColor: rgb(255, 255, 255);
    --titleFontColor: rgb(255, 255, 255);
    --backgroundColor: #E0E2E6;
    --mainColor: #264153;
    --altColor: #264153;
    --buttonColor: #264153;
    --mainBorderColor: rgb(31, 29, 29);
    --altBorderColor: rgb(170, 170, 170);
    --altBackgroundColor: rgb(247, 242, 242);;
}

/*Color settings before try new colors*/
/* * {
    --mainFontColor: #000000;
    --altFontColor: #F7F7F7;
    --backgroundColor: #5B6B4A;
    --mainColor: #5B6B4A;
    --altColor: #d6dbd7;
    --buttonColor: #d6dbd7;
    --mainBorderColor: #6f7570;
    --altBorderColor: rgb(170, 170, 170);
} */