.Manut {
    display: flex;
    background-color: #FAFAFA;
}

.ManutContent {
    padding: 50px;
    padding-bottom: 15px;
    background-color: #FAFAFA;
    flex: 1;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}


.Calendar {
    padding: 20px;
    padding-bottom: 50px;
    background-color: #528b46;
    flex: 1;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 350px;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
   
}
