@import url(https://fonts.googleapis.com/css?family=Titillium+Web);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*{
  font-family: 'Titillium Web', sans-serif !important;
  box-sizing: border-box;
  -webkit-touch-callout: none;
}


textarea:focus, input:focus{
  outline: none !important;;
}

*:focus {
  outline: none !important;;
}

*::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}


* {
    --mainFontColor: #ffffff;
    --altFontColor: #264153;
    --buttonFontColor: rgb(255, 255, 255);
    --titleFontColor: rgb(255, 255, 255);
    --backgroundColor: #E0E2E6;
    --mainColor: #264153;
    --altColor: #264153;
    --buttonColor: #264153;
    --mainBorderColor: rgb(31, 29, 29);
    --altBorderColor: rgb(170, 170, 170);
    --altBackgroundColor: rgb(247, 242, 242);;
}

/*Color settings before try new colors*/
/* * {
    --mainFontColor: #000000;
    --altFontColor: #F7F7F7;
    --backgroundColor: #5B6B4A;
    --mainColor: #5B6B4A;
    --altColor: #d6dbd7;
    --buttonColor: #d6dbd7;
    --mainBorderColor: #6f7570;
    --altBorderColor: rgb(170, 170, 170);
} */
.containerLogin {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  padding-bottom: 30px;
  min-height: 100vh;
}

.rowLogin {
  margin-left: 10vw;
  width: 80vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}


.loginText {
  margin-left: 30px;
  margin-right: 30px;
  font-size: 1.1em;
}

.loginInput {
  font-size: 1.1em;
  height: 15px;
	border: 3px solid var(--mainBorderColor);
  border-radius: 10px;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(0.9rem + 0.9rem * (2 / 3));
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(0.9rem);
  padding-bottom: calc(var(--input-padding-y));
}

.form-label-group input:not(:placeholder-shown)~label {
  padding-top: calc(0.9rem / 2);
  padding-top: calc(var(--input-padding-y) / 2);
  padding-bottom: calc(0.9rem / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #000000;
}

:root {
  --input-padding-x: 1.2rem;
  --input-padding-y: 0.9rem;
}

.form-label-group>label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  line-height: 0;
  color: black;
  border: 1px solid transparent;
  border-radius: .25rem;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
  margin-top: 5px;
}

.form-label-group>input,
.form-label-group>label {
  padding: 0.9rem 1.2rem;
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group {
  position: relative;
  margin-bottom: 10px;
}

.loginButton {
	background-color: var(--buttonColor);
	margin-top: 20px;
	border-radius: 15px;
	vertical-align: center;
	text-align: center;
	width: 100%;
  height: 7vh;
  min-height: 40px;
	border: 3px solid var(--mainBorderColor);
	padding: 3px;
	z-index: 30;
  color: var(--buttonFontColor);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  cursor: pointer;
}

.loginButtonText {
  display: inline;
  margin-left: 10px;
}


.loginButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.loginButton span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.loginButton:hover span {
  padding-right: 25px;
}
.loginButton:hover span:after {
  opacity: 1;
  right: 0;
}
.tableEdit, .tableDelete {
    padding: 3px;
    margin-right: 5px;
    -webkit-transition-duration: 0s; /* Safari */
    transition-duration: 0s;
    color: black
}


.tableEdit:hover, .tableDelete:hover {
    padding: 2px;
    border-radius: 2px;
    border: 1px solid rgba(73, 87, 59, 0.733);
    color: #49573B;
}

.tableEdit:active, .tableDelete:active {
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}
.FrLoading {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.filtersDiv {
    /* border-bottom: 2px solid #49573B; */
    border-bottom: 2px solid #528b46;
    padding-bottom: 15px;
}

.filtersDiv form {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
}

.tableDiv {
    padding-top: 30px;
    padding-bottom: 30px;
}

.filtersDiv form button:not(.MuiButtonBase-root) {
    border: none;
    width: 160px;
    /* background-color: #49573B; */
    background-color: #528b46;
    color: white;
    /* color: black; */
            
    height: 30px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin-right: 6px;
    margin-bottom: 10px;
}

.filtersDiv form button:hover:not(.MuiButtonBase-root) {
    color: white;
}

.filtersDiv form button:active:not(.MuiButtonBase-root) {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
}

.tableDiv {
    padding-bottom: 0;
}
.Manut {
    display: flex;
    background-color: #FAFAFA;
}

.ManutContent {
    padding: 50px;
    padding-bottom: 15px;
    background-color: #FAFAFA;
    flex: 1 1;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}


.Calendar {
    padding: 20px;
    padding-bottom: 50px;
    background-color: #528b46;
    flex: 1 1;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 350px;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
   
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


.app {
	min-height: 100vh;
	-webkit-touch-callout: none;
	margin: 0;
	width: auto;
	background: var(--backgroundColor);
	color: var(--altFontColor);
}

.menuText {
	margin-top: 80px;
	margin-left: 50px;
}

.spinner-grow {
	height: 100px !important;
	width: 100px !important;
	padding: 30px !important;
	margin-left: calc(50vw - 50px);
	margin-top: calc(30vh - 50px);
	float: center;
	color: var(--mainColor);
}

.buttonsDiv {
	width: 100%;
	max-width: 500px;
	align-content: center;
	padding-bottom: 30px;
	margin-top: -35px;
	
}

.containerList {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: top;
	align-items: stretch;
	align-content: stretch;
}

.containerOffer {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: top;
	align-items: stretch;
	align-content: stretch;
}

.listGroupTitle {
	display: flex;
	background-color: var(--altColor);
	color: var(--titleFontColor);
	border: 3px solid var(--mainBorderColor); 
	border-width: 0px 0px 3px 0px;
	height: 40px;
	justify-content: center;
	align-items: center;
	margin-bottom: 0px;
}

.offerTitle {
	display: flex;
	background-color: var(--altColor);
	color: var(--titleFontColor);
	border: 3px solid var(--mainBorderColor); 
	border-width: 0px 0px 3px 0px;
	height: 5vh;
	min-height: 35px;
	justify-content: center;
	align-items: center;
	margin-bottom: 0px;
}

.swipeTitle {
	display: flex;
	background-color: var(--altColor);
	color: var(--titleFontColor);
	border: 3px solid var(--mainBorderColor); 
	border-width: 0px 0px 3px 0px;
	height: 5vh;
	min-height: 35px;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin-bottom: 0px; 
}

.listGroupItems {
	overflow-y: auto;
	color: var(--mainFontColor);
	background-color: var(--altBackgroundColor) !important;
	border-radius: 0;
}

.headerDiv {
	height: 80px;
	min-height: 70px;
}

.itemsDiv {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: top;
	align-items: stretch;
	align-content: stretch;
	flex-grow: 1;
	max-width: 100vw;
	/* height: calc(100vh - 80px); */
}

.modalCloseButton {
	background-color: var(--buttonColor);
	margin-top: 30px;
	border-radius: 15px;
	vertical-align: center;
	text-align: center;
	width: calc(100% - 60px);
	height: 7vh;
	min-height: 40px;
	border: 3px solid var(--mainBorderColor);
	padding: 3px;
	z-index: 30;
	color: var(--buttonFontColor);
	margin-left: 10%;
	margin-right: 10%;
	-webkit-transition: all 0.4s;
	transition: all 0.4s;
	cursor: pointer;
	max-width: 400px;
}


.offerDiv {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: top;
	align-items: stretch;
	align-content: stretch;
	flex-grow: 1;
	max-width: 100vw;
	min-height: 85vh;
	overflow-y: auto;
}

.offerDetail {
	background-color: var(--altBackgroundColor);
	height: 100%;
	min-height: 80vh;
	color: var(--mainFontColor);
}

.offerDetailsRow {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: left;
	align-content: stretch;
	margin-left: 50px;
}

.offerImagesRow {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-content: stretch;
	width: 90vw;
	overflow-x: auto;
	margin-left: 5vw;
	margin-bottom: 8vh;
	margin-top: 3vh;
}

.menuImage {
	float: right;
	margin-right: 30px;
	margin-top: 15px;
	height: 50px;
}

.offerOptionsRow {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-content: stretch;
	width: 90vw;
	margin-left: 5vw;
	margin-bottom: 8vh;
	margin-top: 5vh;
}

.homeImageDiv {
	margin-top: 30px;
}


button:active {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24), 0 6px 25px 0 rgba(0, 0, 0, 0.19);
	-webkit-transform: translateY(2px);
	        transform: translateY(2px);
}

button:hover{
	box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.24), 0 8px 25px 0 rgba(0, 0, 0, 0.19);
}


.listGroupItem:hover {
	box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.24), 0 8px 20px 0 rgba(0, 0, 0, 0.15);
}

.listGroupItem:active {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24), 0 6px 25px 0 rgba(0, 0, 0, 0.19);
	-webkit-transform: translateY(2px);
	        transform: translateY(2px);
}

.homeButton {
	background-color: var(--buttonColor);
	margin-top: 30px;
	border-radius: 15px;
	vertical-align: center;
	text-align: center;
	width: 88%;
	height: 7vh;
	min-height: 40px;
	border: 3px solid var(--mainBorderColor);
	padding: 3px;
	z-index: 30;
	color: var(--buttonFontColor);
	margin-left: 6%;
	margin-right: 6%;
	-webkit-transition: all 0.4s;
	transition: all 0.4s;
	cursor: pointer;
	max-width: 400px;
}


.backButton {
	background-color: var(--buttonColor);
	border-radius: 15px;
	vertical-align: center;
	text-align: center;
	width: 50px;
	height: 35px;
	/* border: 3px solid var(--mainBorderColor); */
	padding: 3px;
	z-index: 30;
	color: var(--buttonFontColor);
	-webkit-transition: all 0.4s;
	transition: all 0.4s;
	cursor: pointer;
	margin-top: 5px;
	margin-left: 5px;
}


.homeButton span {
	cursor: pointer;
	display: inline-block;
	position: relative;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}
.homeButton span:after {
	content: '\00bb';
	position: absolute;
	opacity: 0;
	top: 0;
	right: -20px;
	-webkit-transition: 0.5s;
	transition: 0.5s;
}
.homeButton:hover span {
	padding-right: 25px;
}
.homeButton:hover span:after {
	opacity: 1;
	right: 0;
}

.container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: top;
	align-items: center;
	align-content: stretch;
	flex-grow: 1;
	height: 100%;
}

.dayNav {
	/* align-self: center; */
	width: 100vw;
	height: 60px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
}

.nav {
	height: 60px;
	width: 100vw;
}

.react-date-picker, .react-date-picker *, .react-date-picker *:before, .react-date-picker *:after {
	border-radius: 5px;
}


.react-date-picker__button__icon {
	stroke: var(--buttonFontColor) !important;	
}

.react-date-picker, .react-date-picker *, .react-date-picker *:before, .react-date-picker *:after {
	border-radius: 5px;
	background: var(--buttonColor);
	color: var(--buttonFontColor);
	border-width: 0 !important;
}

.react-date-picker {
	border: 3px solid var(--mainBorderColor) !important;
}

.react-calendar {
	background: var(--buttonColor) !important;
	color: var(--buttonFontColor) !important;	
}

.react-calendar abbr {
	background: transparent !important;
}



.react-date-picker__calendar {
	border: 3px solid var(--mainBorderColor) !important;
	margin-left: -3px;
}

.react-calendar__tile:hover abbr{
	color: var(--mainBorderColor) !important;	 
}


.datePicker {
	align-self: center;
	height: 35px;
	background: var(--buttonColor);
	color: var(--buttonFontColor);
}

.navButtons {
	align-self: center;
	height: 35px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: top;
	align-items: center;
	align-content: center;
	flex-grow: 1;
}

.navButton {
	background: var(--buttonColor);
	color: var(--buttonFontColor);
	border-radius: 5px;
	height: 35px;
	padding-bottom: 3px;
	margin-left: 5px;
	border: 3px solid var(--mainBorderColor);
}

